import "./Contact.scss";
import { useEffect, useState } from "react";

// animation imports
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { SplitText } from "gsap/all";
import CustomEase from "gsap/CustomEase";

const Contact = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Base circle sizes removed as we'll use the square animation now

  useGSAP(() => {
    gsap.set(".square-frame", {
      transformOrigin: "center center",
      x: -141,
      y: -217,
    });
    const loopTime = 16;
    const ballDuration = loopTime / 8;
    const introRotTime = 1.3;
    const introRotDelay = 0;
    const squareDelay = introRotTime + introRotDelay;

    const titleText = new SplitText(".contact-title", {
      type: "chars",
      position: "absolute",
    });

    const linksST = new SplitText(".contact-links span", {
      type: "chars",
      position: "relative",
    });

    const constEase = CustomEase.create(
      "custom",
      `M0,0,C0.1,0.025,0.125,0.225,0.25,0.25,0.375,0.275,0.375,0.475,0.5,0.5,0.625,0.525,0.625,0.725,0.75,0.75,0.875,0.775,0.875,0.975,1,1`
    );

    let contactTl = gsap.timeline({ delay: 0.5 });

    contactTl
      .from(
        ".square__large",
        {
          scaleX: 0,
          duration: 2,
          ease: "power4.inOut",
          transformOrigin: "right center",
        },
        0
      )
      .to(
        ".square-frame",
        {
          rotation: 16,
          duration: introRotTime,
          ease: CustomEase.create(
            "custom",
            "M0,0,C0.234,0.528,0.524,0.828,1,1"
          ),
        },
        introRotDelay
      )
      .from(
        ".square__small",
        {
          scaleX: 0,
          duration: 2,
          ease: "power4",
          transformOrigin: "right center",
        },
        1.1
      )
      .set([".ball", ".dark-patch"], { visibility: "visible" }, 1.3)
      .fromTo(
        ".ball",
        {
          x: -206,
        },
        {
          x: 58,
          ease: "ballBounce",
          duration: 2,
        },
        1.3
      );

    let squareTl = gsap.timeline({
      delay: squareDelay + 0.5,
      repeat: -1,
    });

    squareTl.to(".square", {
      duration: loopTime,
      ease: constEase,
      rotation: "+=360",
      transformOrigin: "center center",
    });

    let ballTl = gsap.timeline({
      repeat: -1,
      delay: 4.65,
      repeatDelay: ballDuration,
      defaults: {
        ease: "ballBounce",
        duration: ballDuration,
      },
    });

    ballTl
      .to(".ball", {
        y: -58,
      })
      .to(
        ".ball",
        {
          x: 0,
        },
        loopTime / 4
      )
      .to(
        ".ball",
        {
          y: 0,
        },
        loopTime / 2
      )
      .to(
        ".ball",
        {
          x: 58,
        },
        loopTime * 0.75
      );

    // title and links animation
    let titleTl = gsap.timeline({
      ease: "power2",
      duration: 2,
      delay: 1.5,
    });

    titleTl
      .from(
        titleText.chars,
        {
          opacity: 0,
          y: -120,
          stagger: 0.05,
        },
        0
      )
      .from(
        ".contact-links span",
        {
          opacity: 0,
          y: 60,
          rotationX: 90,
          stagger: 0.05,
        },
        0
      );
  });

  return (
    <>
      <div className="contact-container">
        <svg className="contact-animation" viewBox="0 0 716 1008">
          <g className="square-frame">
            <g className="square">
              <rect
                className="square__large"
                width="868"
                height="868"
                fill="black"
              />
              <rect
                className="square__small"
                x="303"
                y="303"
                width="264"
                height="264"
                fill="var(--accent-tan)"
              />
              <circle
                className="ball"
                cx="405"
                cy="463"
                r="105"
                fill="black"
              />
            </g>
          </g>
        </svg>

        <div className="contact-content">
          <div className="title-wrap">
            <h1 className="contact-title">contact</h1>
          </div>
          <div className="links-wrap">
            <div className="contact-links">
              <span>
                <a
                  href="https://github.com/KevBarbour15"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  github
                </a>
              </span>
              <span>
                <a
                  href="https://www.linkedin.com/in/kevin-barbour-70914b298/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  linkedin
                </a>
              </span>
              <span>
                <a href="mailto:kevintbarbour@gmail.com">email</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
