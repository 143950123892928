import "./ProjectModal.scss";
import { useRef, useEffect, useState } from "react";

// animation imports
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { SplitText } from "gsap/all";

const ProjectModal = ({ isVisible, project, onClose, accentColor }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const overlayRef = useRef(null);
  const modalTimelineRef = useRef(null);

  useEffect(() => {
    if (isVisible) setShouldRender(true);
  }, [isVisible]);

  useGSAP(() => {
    if (isVisible) {
      setShouldRender(true);
      openModalAnimation();
    } else {
      closeModalAnimation();
    }
  }, [isVisible]);

  useGSAP(() => {
    if (shouldRender && isVisible) {
      // Ensure all elements are rendered
      setTimeout(() => {
        openModalAnimation();
      }, 5);
    }
  }, [shouldRender, isVisible]);

  const openModalAnimation = () => {
    if (!overlayRef.current) return;
    let modalTl = gsap.timeline();
    modalTimelineRef.current = modalTl;

    let descriptionST = new SplitText(".modal-body p", {
      type: "lines",
    });

    let titleST = new SplitText(".modal-title", {
      type: "chars, words",
      position: "relative",
      wordSpacing: "0.25em",
    });

    // Set initial state manually
    gsap.set(".modal-link-container", {
      x: -300,
    });

    modalTl
      .to(overlayRef.current, { opacity: 1, duration: 0.15, ease: "expo" })
      .from(
        ".modal-content",
        {
          rotationX: 90,
          duration: 0.8,
          ease: "expo",
        },
        0.15
      )
      .from(
        titleST.chars,
        {
          opacity: 0,
          scale: 0,
          y: 20,
          rotationX: 45,
          stagger: {
            amount: 0.25,
            from: "start",
          },
          ease: "back.out(1.4)",
        },
        0.3
      )
      .from(
        descriptionST.lines,
        {
          delay: 0.3,
          opacity: 0,
          rotationX: 90,
          y: 45,
          ease: "expo",
          duration: 0.75,
        },
        0.3
      )
      .to(
        ".modal-link-container",
        {
          x: 0,
          duration: 0.8,
          ease: "back.out(1.4)",
        },
        0.6
      );
  };

  const closeModalAnimation = () => {
    if (!overlayRef.current) return;

    if (modalTimelineRef.current) {
      modalTimelineRef.current
        .reverse()
        .eventCallback("onReverseComplete", () => {
          setShouldRender(false);
        });
    } else {
      setShouldRender(false);
    }
  };

  if (!shouldRender) return null;

  return (
    <div className="modal-overlay" ref={overlayRef} onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <p className="modal-title">{project.project_name}</p>

          {project.website && (
            <div className="modal-link-container">
              <a
                className="modal-link"
                href={project.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                visit website
              </a>
            </div>
          )}

          <button className="close-button" onClick={onClose}>
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>

        <div className="modal-body">
          <p>{project.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectModal;
