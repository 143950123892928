import "./About.scss";
import colors from "./color-palettes.json";
import { useState, useEffect } from "react";

// animation imports
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { SplitText } from "gsap/all";

// Add these constants
const baseSizes = [410, 375, 335, 295, 258, 220, 187, 159, 135, 116, 100];
const mobileOffset = 50;
const desktopOffset = 200;

const About = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const circleSizes = baseSizes.map(
    (size) => size + (isMobile ? mobileOffset : desktopOffset)
  );

  // set random color palette
  const [palette, setPalette] = useState(() => {
    return colors["color-palette"][5];
  });

  useGSAP(() => {
    const aboutST = new SplitText(".about-title", {
      type: "chars",
      charsClass: "char",
    });

    let tl = gsap.timeline();
    tl.from(".circles circle", {
      delay: 0.75,
      y: 800,
      ease: "expo",
      duration: 3,
      stagger: {
        each: -0.15,
      },
    })
      .set(".circles circle", {
        stagger: {
          each: 0.15,
        },
      })
      .from(
        ".circles circle",
        {
          opacity: 0,
          scale: 0.9,
          ease: "back(5)",
          duration: 1,
          repeat: -1,
          stagger: {
            each: -0.05,
            repeat: -1,
            yoyo: true,
          },
          transformOrigin: "center center",
        },
        0.75
      )
      .from(
        aboutST.chars,
        {
          y: "random(-50 50)",
          opacity: 0,
          duration: 1,
          stagger: 0.1,
        },
        1
      )
      .to(
        ".about-text-container",
        {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        },
        1.2
      );
  });

  return (
    <>
      <div className="about-container">
        <div className="about-animation">
          <svg
            className="svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 716 1008"
          >
            <g fill="none" fillRule="evenodd">
              <g className="circles" transform="translate(0 292)">
                <circle cx="358" cy="358" r={circleSizes[0]} />
                <circle cx="358" cy="358" r={circleSizes[1]} />
                <circle cx="358" cy="358" r={circleSizes[2]} />
                <circle cx="358" cy="358" r={circleSizes[3]} />
                <circle cx="358" cy="358" r={circleSizes[4]} />
                <circle cx="358" cy="358" r={circleSizes[5]} />
                <circle cx="358" cy="358" r={circleSizes[6]} />
                <circle cx="358" cy="358" r={circleSizes[7]} />
                <circle cx="358" cy="358" r={circleSizes[8]} />
                <circle cx="358" cy="358" r={circleSizes[9]} />
                <circle cx="358" cy="358" r={circleSizes[10]} />
              </g>
            </g>
          </svg>
          <div className="about-text-container">
            <div className="about-text-container-inner">
              <p>i'm kevin barbour.</p>
              <p>
                i'm a freelance full-stack developer based in brooklyn, ny
                specializing in building dynamic, scalable applications with a
                strong foundation in both frontend and backend development. With
                extensive experience in content management systems—whether
                custom-built or Shopify-based—I ensure seamless functionality
                and intuitive user experiences. My focus is on crafting visually
                engaging and robust solutions that align with business goals.
              </p>
              <p>
                I thrive on new ideas, challenges, and innovative solutions.
                Blending creativity with problem-solving, I take pride in
                bringing concepts to life in a way that is both effective and
                impactful. If you're looking for a developer with strong
                technical expertise, interpersonal skills, and a creative edge,
                let's connect. I'm currently looking for new opportunities. If
                you're interested in working with me, please reach out.
              </p>
            </div>
          </div>
        </div>
        <h1 className="about-title">about</h1>
      </div>
    </>
  );
};

export default About;
