import "./Skills.scss";

import Header from "../../components/Header/Header";

import { useRef, useEffect } from "react";
import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";

gsap.registerPlugin(PixiPlugin);

const Skills = () => {
  const stageRef = useRef(null);
  const appRef = useRef(null);

  useEffect(() => {
    if (!stageRef.current) return;

    const stage = stageRef.current;
    const app = new PIXI.Application({
      width: window.innerWidth,
      height: 1000,
      backgroundColor: 0xdae0d2,
      antialias: true,
    });

    appRef.current = app;
    stage.appendChild(app.view);

    const gridSize = 12;
    const circD = Math.min(window.innerWidth / (gridSize + 1), 64);
    const circOffsetX = 0.11111;
    const circOffsetY = 0.15873;
    const color1 = 0x01aff6;
    const color2 = 0xf20085;
    const color3 = 0xffd036;
    const animDuration = 0.8;

    app.ticker.stop();

    gsap.ticker.add(() => {
      app.ticker.update();
    });

    // Create circles
    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        const container = new PIXI.Container();
        const circContainer1 = new PIXI.Container();
        const circContainer2 = new PIXI.Container();
        const circContainer3 = new PIXI.Container();

        // Circle 1
        const circle1 = new PIXI.Graphics();
        circle1.lineStyle(0);
        circle1.beginFill(color1, 1);
        circle1.drawCircle(0, 0, circD / 2);
        circle1.endFill();
        circle1.blendMode = PIXI.BLEND_MODES.MULTIPLY;
        circContainer1.addChild(circle1);
        container.addChild(circContainer1);

        // Circle 2
        const circle2 = new PIXI.Graphics();
        circle2.lineStyle(0);
        circle2.beginFill(color2, 1);
        circle2.drawCircle(0, 0, circD / 2);
        circle2.endFill();
        circle2.blendMode = PIXI.BLEND_MODES.MULTIPLY;
        circContainer2.addChild(circle2);
        circContainer2.x = -circOffsetX * circD;
        circContainer2.y = circOffsetY * circD;
        container.addChild(circContainer2);

        // Circle 3
        const circle3 = new PIXI.Graphics();
        circle3.lineStyle(0);
        circle3.beginFill(color3, 1);
        circle3.drawCircle(0, 0, circD / 2);
        circle3.endFill();
        circle3.blendMode = PIXI.BLEND_MODES.MULTIPLY;
        circContainer3.addChild(circle3);
        circContainer3.x = circOffsetX * circD;
        circContainer3.y = circOffsetY * circD;
        container.addChild(circContainer3);

        app.stage.addChild(container);
        container.x = i * circD + circD / 2 + i * 2;
        container.y = j * circD + circD / 2 + j * 2;
      }
    }

    app.stage.x = 2;

    // Animation
    gsap
      .timeline({ delay: 0.2 })
      .from(app.stage.children, {
        pixi: { scale: 0, rotation: 360 },
        duration: 2,
        ease: "power4",
        stagger: {
          each: 0.1,
          grid: [gridSize, gridSize],
          from: [0, 1],
        },
      })
      .to(
        app.stage.children,
        {
          duration: animDuration,
          ease: "sine.inOut",
          stagger: {
            each: 0.1,
            repeat: -1,
            yoyo: true,
            grid: [gridSize, gridSize],
            from: [0, 1],
            onStart: function () {
              gsap.to(this.targets()[0].children, {
                pixi: { scale: 0.15 },
                duration: animDuration,
                ease: "sine.inOut",
                repeat: -1,
                yoyo: true,
              });
            },
          },
        },
        0.1
      );

    // Resize handler
    const handleResize = () => {
      const vh = window.innerHeight;
      const sh = stage.offsetHeight;
      const scaleFactor = vh / sh;

      // Update app size
      app.renderer.resize(window.innerWidth, 1000);

      // Recalculate circle positions
      const newCircD = Math.min(window.innerWidth / (gridSize + 1), 64);
      app.stage.children.forEach((container, index) => {
        const i = index % gridSize;
        const j = Math.floor(index / gridSize);
        container.x = i * newCircD + newCircD / 2 + i * 2;
        container.y = j * newCircD + newCircD / 2 + j * 2;
      });

      gsap.set(stage, { scale: scaleFactor < 1 ? scaleFactor : 1 });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      if (appRef.current) {
        appRef.current.destroy(true);
      }
    };
  }, []);

  return (
    <div className="skills-container">
      <div ref={stageRef} className="stage"></div>
    </div>
  );
};

export default Skills;
