import "./Header.scss";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useEffect, useRef } from "react";

const Header = ({ color, shadow, background }) => {
  const headerRef = useRef(null);

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        const height = headerRef.current.offsetHeight;
        document.documentElement.style.setProperty(
          "--header-height",
          `${height}px`
        );
      }
    };

    // Initial measurement
    updateHeaderHeight();

    // Update on window resize
    window.addEventListener("resize", updateHeaderHeight);

    return () => window.removeEventListener("resize", updateHeaderHeight);
  }, []);

  useGSAP(() => {
    let headerTl = gsap.timeline({ delay: 0.5 });

    headerTl
      .set(".header", {
        width: "0%",
        opacity: 1,
        padding: "16px 0",
      })
      .to(".header", {
        width: "100%",
        opacity: 1,
        duration: 0.5,
        ease: "expo",
        padding: "16px",
      })
      .from(
        ".header-menu-container span",
        {
          y: -50,
          rotationX: 45,
          ease: "expo",
          stagger: -0.5,
        },
        0.5
      );
  }, []);

  return (
    <div className="header" ref={headerRef}>
      <header className="header-content">
        <div className="header-menu-container">
          <span>
            <Link
              className="nav-link"
              to="/"
              style={{ color: color, textShadow: shadow }}
            >
              home
            </Link>
          </span>

          <span>
            <Link
              className="nav-link"
              to="/about"
              style={{ color: color, textShadow: shadow }}
            >
              about
            </Link>
          </span>

          <span>
            <Link
              className="nav-link"
              to="/work"
              style={{ color: color, textShadow: shadow }}
            >
              work
            </Link>
          </span>
          <span>
            <Link
              className="nav-link"
              to="/contact"
              style={{ color: color, textShadow: shadow }}
            >
              contact
            </Link>
          </span>
        </div>
      </header>
    </div>
  );
};

export default Header;
