import { useState } from "react";
import "./Work.scss";
import projectsData from "../../projects.json";

import ProjectModal from "../../components/ProjectModal/ProjectModal";

// animation imports
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

import arrow from "../../right-arrow.svg";

const Work = () => {
  // set modal state
  const [isModalOpen, setModalOpen] = useState(false);

  // set project state
  const [project, setProject] = useState("");

  // open modal function
  const handleOpenModal = (idx) => {
    setProject(projectsData.projects[idx]);
    setModalOpen(true);
  };

  useGSAP(() => {
    let tl = gsap.timeline({ delay: 0.5 });

    // set up the cubes
    gsap.set(".cuboid", {
      transformPerspective: 800,
      transformOrigin: "left center",
    });

    tl.to(".base", {
      scaleX: 0,
      duration: 3,
      ease: "power4.inOut",
      transformOrigin: "right center",
      stagger: 0.1,
    }).from(
      ".face--front .word",
      {
        x: "70%",
        duration: 3,
        ease: "power4",
        stagger: 0.15,
      },
      0
    );

    let targets = gsap.utils.toArray(".info");

    targets.forEach(function (item, i) {
      let bg = item.querySelectorAll(".project-line__bg");
      let txt = item.querySelectorAll(".project-line__txt");

      let ttl = gsap.timeline({
        defaults: {
          duration: 1,
          stagger: 0.15,
        },
      });

      ttl
        .from(bg, {
          scaleX: 0,
          ease: "power2.in",
          transformOrigin: "left top",
        })
        .set(txt, {
          opacity: 1,
        })
        .to(bg, {
          scaleX: 0,
          ease: "power2",
          transformOrigin: "right top",
        });

      tl.add(ttl, i / 8);
    });

    let newTl = gsap.timeline({
      delay: 3.25,
      repeat: -1,
      repeatDelay: 1,
      defaults: {
        stagger: 0.25,
        duration: (i) => 2 + i * 0.4,
      },
    });
    newTl
      .to(".cuboid", {
        rotateX: -180,
        ease: "power2.inOut",
      })
      .set(".face--front .word", { y: 0 })
      .from(
        ".face--back .word",
        {
          y: "-100%",
          duration: (i) => 5 + i * 0.2,
          ease: "power2",
          immediateRender: false,
        },
        0
      )
      .to(
        ".cuboid",
        {
          rotateX: -360,
          ease: "power2.inOut",
        },
        8
      )
      .fromTo(
        ".face--front .word",
        {
          y: "-100%",
        },
        {
          y: "0%",
          duration: (i) => 5 + i * 0.2,
          ease: "power2",
          immediateRender: false,
        },
        8
      );
  });

  return (
    <>
      <div className="projects-container">
        <div className="projects">
          <div className="line"></div>
          <div className="bar bar--1">
            <div className="cuboid cuboid--1">
              <div className="face face--front">
                <h1 className="word">
                  <span className="highlight">highlighted</span>
                  work
                </h1>
                <div className="base"></div>
              </div>
              <div className="face face--back">
                <h1 className="word" aria-hidden="true">
                  <span className="highlight">highlighted</span>
                  work
                </h1>
              </div>
              <div className="face face--top">
                <h1 className="word" aria-hidden="true">
                  my
                </h1>
              </div>
              <div className="face face--bottom">
                <h1 className="word" aria-hidden="true">
                  my
                </h1>
              </div>
            </div>
          </div>
          <div className="bar bar--2">
            <div className="cuboid cuboid--2">
              <div className="face face--front">
                <h1 className="word">
                  <span className="highlight">highlighted</span>
                  work
                </h1>
                <div className="base"></div>
              </div>
              <div className="face face--back">
                <h1 className="word" aria-hidden="true">
                  <span className="highlight">highlighted</span>
                  work
                </h1>
              </div>
              <div className="face face--top">
                <h1 className="word" aria-hidden="true">
                  my
                </h1>
              </div>
              <div className="face face--bottom">
                <h1 className="word" aria-hidden="true">
                  my
                </h1>
              </div>
            </div>
          </div>
          <div className="bar bar--3">
            <div className="cuboid cuboid--3">
              <div className="face face--front">
                <h1 className="word">
                  <span className="highlight">highlighted</span>
                  work
                </h1>
                <div className="base"></div>
              </div>
              <div className="face face--back">
                <h1 className="word" aria-hidden="true">
                  <span className="highlight">highlighted</span>
                  work
                </h1>
              </div>
              <div className="face face--top">
                <h1 className="word" aria-hidden="true">
                  my
                </h1>
              </div>
              <div className="face face--bottom">
                <h1 className="word" aria-hidden="true">
                  my
                </h1>
              </div>
            </div>
          </div>
          <div className="bar bar--4">
            <div className="cuboid cuboid--4">
              <div className="face face--front">
                <h1 className="word">
                  <span className="highlight">highlighted</span>
                  work
                </h1>
                <div className="base"></div>
              </div>
              <div className="face face--back">
                <h1 className="word" aria-hidden="true">
                  <span className="highlight">highlighted</span>
                  work
                </h1>
              </div>
              <div className="face face--top">
                <h1 className="word" aria-hidden="true">
                  my
                </h1>
              </div>
              <div className="face face--bottom">
                <h1 className="word" aria-hidden="true">
                  my
                </h1>
              </div>
            </div>
          </div>
          <div className="bar bar--5">
            <div className="cuboid cuboid--5">
              <div className="face face--front">
                <h1 className="word">
                  <span className="highlight">highlighted</span>
                  work
                </h1>
                <div className="base"></div>
              </div>
              <div className="face face--back">
                <h1 className="word" aria-hidden="true">
                  <span className="highlight">highlighted</span>
                  work
                </h1>
              </div>
              <div className="face face--top">
                <h1 className="word" aria-hidden="true">
                  my
                </h1>
              </div>
              <div className="face face--bottom">
                <h1 className="word" aria-hidden="true">
                  my
                </h1>
              </div>
            </div>
          </div>
          <div className="bar bar--6">
            <div className="cuboid cuboid--6">
              <div className="face face--front">
                <h1 className="word">
                  <span className="highlight">highlighted</span>
                  work
                </h1>
                <div className="base"></div>
              </div>
              <div className="face face--back">
                <h1 className="word" aria-hidden="true">
                  <span className="highlight">highlighted</span>
                  work
                </h1>
              </div>
              <div className="face face--top">
                <h1 className="word" aria-hidden="true">
                  my
                </h1>
              </div>
              <div className="face face--bottom">
                <h1 className="word" aria-hidden="true">
                  my
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="projects-list">
          {projectsData.projects.map((project, index) => (
            <div key={index} className="info">
              <span className="project-line">
                <button
                  className="project-line__txt"
                  onClick={() => handleOpenModal(index)}
                  id={`scramble_${index + 1}`}
                >
                  {project.project_name}
                  <span>
                    <img src={arrow} alt="arrow" />
                  </span>
                </button>
                <span className="project-line__bg"></span>
              </span>
            </div>
          ))}
        </div>
      </div>
      <ProjectModal
        isVisible={isModalOpen}
        project={project}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default Work;
